import React, { useState, memo } from "react";
import ReactDOM from 'react-dom';
import styled from "styled-components";
import { FaPhone,FaLock } from "react-icons/fa";
import { Drawer,Layout, Menu } from "antd";
import {PopUpContainer, PopUp,PopUpTitle, Wrap, ButFermer} from "../DesignSystem/PopUp";
import FormDemandeDeContactHP from "../Forms/FormDemandeDeContact";
import { Link } from "@reach/router";
import Logo from "../../images/LogoHP.webp";
import {TbMessage2Question} from 'react-icons/tb'
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";
import { FaRegFileAlt } from "react-icons/fa";
import { scroller } from 'react-scroll';
import FormSearch from "../Forms/FormSearch";

const { Sider } = Layout;
const { SubMenu } = Menu;

const StyledDrawer = styled(Drawer)`
  & > .ant-drawer-body {
    padding: 10px 0 !important;
  }
  & > .ant-drawer-body > aside {
    max-width: 100% !important;
    min-width: 100% !important;
    width: 100% !important;
    & > div > ul > li > div > i {
      display: none;
    }
  }
`;
const StyledSubMenu = styled(SubMenu)`
font: 700 16px Quicksand, sans-serif !important;
color: #735f66 !important;

  width: 100%;
  &>div>
  & > div.ant-menu-submenu-title {
    white-space: wrap !important;
    padding: 10px 10px 10px 25px !important;
    height: unset !important;
    line-height: 25px !important;
  }
  &.niv1 > .ant-menu-submenu-title {
    color: #735f66 !important;
    text-transform: uppercase;
    font-size: 18px;
  }
  &.niv2 > .ant-menu-submenu-title {
    color: ${(props) => props.theme.bleuClair};
    text-transform: uppercase;
    &> i {
      display: none;
    }
  }
  &.niv1.ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: ${(props) => props.theme.rose};
    text-transform: uppercase;
  }
  &.niv2.ant-menu-submenu-selected > .ant-menu-submenu-title {
    /* text-transform: uppercase;
    border-top: 2px solid ${(props) => props.theme.jaune};
    background: ${(props) => props.theme.bleuHP}; 
    color: #fff; */
  }
  &.niv2.ant-menu-submenu-selected
    > ul
    > .niv3.ant-menu-item.ant-menu-item-selected
    > .ant-menu-title-content {
    color: ${(props) => props.theme.bleuHP} !important;
  }

  &.niv2.ant-menu-submenu > ul > .niv3.ant-menu-item > .ant-menu-title-content > a {
    color: ${(props) => props.theme.bleuHP} !important;
    font: 700 16px Quicksand, sans-serif !important;
    border: none !important;
  }
`;
const StyledMenu = styled(Menu)`
`;
const StyledImg = styled.img`
  //box-sizing: border-box;
  //flex-shrink: 0;
  /* width: 104px;
  height: 72px; */
  //position: relative;
  //padding: 0px 92px 0px 92px;
`;
const StyledMenuItem = styled(Menu.Item)`
  white-space: wrap !important;
  height: unset !important;
  line-height: 25px !important;
  padding: 10px 10px 10px 60px !important;
  &.plus {
    padding: 10px 10px 10px 30px !important;
  }
  &>.ant-menu-title-content>div {
    &>svg {
      margin-right: 10px;
      color: ${(props) => props.theme.bleuHP}
    }
  }
    
  &>.ant-menu-title-content>div > a {
    border: none !important;
    color: #735f66 !important;
    font: 700 17px Quicksand, sans-serif !important;
    text-transform: uppercase;
    &>svg {
      margin-right: 10px;
      color: ${(props) => props.theme.bleuHP}
    }
  }
`;

const DrawerMenu = memo(({ dataMenuHaut,visible,onClose,showBtnDoc }) => {
  let menuHautData = [...dataMenuHaut];
  menuHautData.splice(6, 1);

    const handleClick = () => {
        scroller.scrollTo('formProd', {
          duration: 2000,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: -380, // Same offset as in your LinkRose component
        });
      };
    const [openKeys, setOpenKeys] = useState([]);
    const [isVisible, setIsVisible] = useState(visible);
    const showDrawer = () => {
        onClose(); // This will call the `toggleDrawer` function from `ButtonsVolant`
      };
    const onOpenChange = (keys) => {
      const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
      const isNiv1 = latestOpenKey && latestOpenKey?.startsWith('sub');
  
      // If the latest opened submenu is a niv1 submenu, only keep it open
      // Otherwise, keep all the submenus open
      setOpenKeys(isNiv1 ? [latestOpenKey] : keys);
    };
  
    const [formQuestion, setFormQuestion] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
  
    // const showDrawer = () => {
    //   setIsVisible(true);
    // };
    // const onClose = () => {
    //   setIsVisible(false);
    //   setOpenKeys([]);
    // };
    return (
<StyledDrawer
placement="right"
closable={true}
onClose={onClose}
open={visible}
width={400}
extra={<StyledImg src={Logo} style={{ width: " 120px" }} />}
>

<Sider width={400}>
  {/* <StyledImg src={Logo} /> */}
  <StyledMenu
    mode="inline"
    openKeys={openKeys}
    onOpenChange={onOpenChange}
    style={{ height: "100%", borderRight: 0 }}
  >
    {menuHautData &&
      menuHautData
        .sort((a, b) => (a.Tri < b.Tri ? -1 : 1))
        .map((menu, i) => (
          <StyledSubMenu id="menu"
            key={`sub${i}`}
            title={menu?.Lib}
            className={"niv1"}
          >
            {menu?.Sous &&
              menu?.Sous.map((bleu, i) => (
                <StyledSubMenu
                  key={bleu.id}
                  title={bleu?.Lib}
                  className={"niv2"}
                >
                  {bleu?.Sous &&
                    bleu?.Sous.map((sous) => (
                      <StyledMenuItem
                        key={sous.id}
                        className={"niv3"}
                      >
                        <Link to={sous?.slug} onClick={onClose}>
                          {sous?.Lib}
                        </Link>
                      </StyledMenuItem>
                    ))}
                </StyledSubMenu>
              ))}
          </StyledSubMenu>
          
        ))}
         <StyledMenuItem className="plus" key="3">
        <div><a href="/membre"> <FaLock/>Accès client / membre</a></div>
        </StyledMenuItem>
        <StyledMenuItem className="plus" key="2">
        <div><a href="tel:33153430210"> <FaPhone/>01 53 43 02 10</a></div>
        </StyledMenuItem>
        <StyledMenuItem className="plus" key="1">
        <div><TbMessage2Question/><a onClick={() => {
        setFormQuestion(true);
        onClose();
      }}>Demander conseil ?</a></div>
      </StyledMenuItem>
        <StyledMenuItem className="plus" key="1">
        <FormSearch onCloseRecherche={onClose} className={"menu"}/>
      </StyledMenuItem>
      {formQuestion && ReactDOM.createPortal(
        <PopUpContainer>
          <PopUp className={"form"} gap={"0"}>
              <PopUpTitle>Demander conseil</PopUpTitle>
              <ButFermer onClick={() => setFormQuestion(false)}><IoCloseCircleOutline/></ButFermer>
                <Wrap>
                <FormDemandeDeContactHP className="" onClick={() => setFormQuestion(false)}/>
                </Wrap>
            </PopUp>
        </PopUpContainer>,
        document.getElementById('portal')
      )} 
     
      {showBtnDoc && <StyledMenuItem className="plus" key="3">
        <div><FaRegFileAlt style={{color: "#195aa0", marginRight:10}}/><a onClick={() => {
        handleClick();
        onClose();
      }}>Recevoir une documentation</a></div>
      </StyledMenuItem>}
  </StyledMenu>
</Sider>

</StyledDrawer>
    );
  });
  export default DrawerMenu;