import React, {useEffect,useState, memo} from 'react';
import ReactDOM from 'react-dom';
import { FloatButton } from "antd";
import { FaRegFileAlt } from "react-icons/fa";
import { AiOutlineMenu } from "react-icons/ai";
import { BiQuestionMark } from "react-icons/bi";
import DrawerMenu from "./DrawerMenuMob";
import { PopUpContainer, PopUp, PopUpTitle, Wrap, ButFermer } from "../DesignSystem/PopUp";
import FormDemandeDeContactHP from "../Forms/FormDemandeDeContact";
import styled from 'styled-components';
import { scroller } from 'react-scroll';
import { useStaticQuery, graphql } from "gatsby";
import { IoCloseCircleOutline } from "react-icons/io5";

const FloatButtonGroupStyled  = styled(FloatButton.Group)`
position: fixed;
transition: opacity 0.7s ease-in-out;
height: 50px;
max-height: 150px;
z-index: 999;
&.hidden {
opacity: 0;
display: none;
}
top: 450px;
@media (max-width: 768px) {
    top: 50px;
  }
right: 20px;
transition: right 0.5s ease-in-out; 
&.show {
  opacity: 1;
}
&>button {
  height: 50px;
  width: 50px;
  box-shadow: 0px 2px 2px rgba(0,0,0,0.25);
  transition: margin 0.2s ease-in-out, width 0.2s ease-in-out, border-radius 0.2s ease-in-out, opacity 18s ease-in-out;
    &>.ant-float-btn-body {
      height: 50px !important;
      width: 50px !important;
      background-color:#fff !important;
      &>div>.ant-float-btn-icon  {
      width: 25px !important;
      font-size: unset !important;
      }
      &>div>.ant-float-btn-icon > svg {
      color: ${props => props.theme.bleuHP};
      font-size: 25px;
      }
      &>.ant-float-btn-content {
        flex-direction: row-reverse;
      } 
      &>.ant-float-btn-content >.ant-float-btn-description {
      opacity: 0;
      width: 0;
      color: ${props => props.theme.bleuHP};
      } 
    }
  &:hover {
    width: 300px;
    border-radius: 50px;
    margin-left: -250px;
    &>.ant-float-btn-body {
    width: 100% !important;
    border-radius: 50px !important;
      &>.ant-float-btn-content>.ant-float-btn-description {
        opacity: 1 !important;
      }
    }
    &>.ant-float-btn-body>.ant-float-btn-content >.ant-float-btn-icon  {
    margin: 0 5px;
    } 
    &>.ant-float-btn-body>.ant-float-btn-content >.ant-float-btn-description {
      transition: opacity 0.9s ease-in-out,width 0.3s ease-in-out;
      display: flex;
      font-size: 20px;
      opacity: 1;
      width: 100%;
    }
  }  
  &#menu:hover {
    width: initial;
    border-radius: 50% !important;
    margin-left: initial !important;
    &>.ant-float-btn-body {
    width: 50px !important;
    border-radius: 50% !important;
    }
    &>.ant-float-btn-body>.ant-float-btn-content >.ant-float-btn-icon  {
      margin: initial;
    } 
    &>.ant-float-btn-body>.ant-float-btn-content >.ant-float-btn-description {
    display: initial;
    font-size: initial;
    }
  }

  
  
  @media (max-width: 768px) {
    top: 50px;
  }
}
`

const ButtonsVolant = memo( ({showBtn, showBtnDoc, showBtnMenu, scrollValue,idContenu}) => {
  // const [dataMenuHaut, setDataMenuHaut] = useState([]);
  const [visible, setVisible] = useState(false);
  const [formQuestion, setFormQuestion] = useState(false);
  const [showButtonsOnScroll, setShowButtonsOnScroll] = useState(false);
  const [classShow, setClassShow] = useState('hidden');
  const data = useStaticQuery(graphql`
    query MyQuery {
      allMenuHaut {
    totalCount
    edges {
      node {
        ID
        Type
        Version
        Lib
        slug
        Tri
        BullAide
        Colonne
        Droit {
          Etat
          TypeCts
          AuthentLevel
        }
        Sous {
          ID
          Type
          Version
          Lib
          slug
          Tri
          BullAide
          Colonne
          Droit {
            Etat
            TypeCts
            AuthentLevel
          }
          Sous {
            ID
            Type
            Version
            Lib
            slug
            Tri
            BullAide
            Colonne
            Droit {
              Etat
              TypeCts
              AuthentLevel
            }
          }
        }
      }
    }
    nodes {
      id
    }
  }

    }`)
  useEffect(() => {
    const checkScroll = () => {
      if (document.documentElement.scrollTop > scrollValue) {
        setClassShow('show');
      } else {
        setClassShow('hidden');
      }
    };

    window.addEventListener('scroll', checkScroll);

    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  }, [scrollValue]);
  const toggleDrawer = () => {
    setVisible(!visible);
  };
  // useEffect(() => {
  //   axios.get(`https://hpv6.yogan.pro/menuhaut?{"CleCtrl":"TaTaYoYo","act":"MenuHaut"}`).then(res => {
   
  //     setDataMenuHaut(res.data?.MenuHaut ? res.data.MenuHaut : []);
  //   }
  //   ).catch(err => {
  //     console.log(err);
  //   }
  //   );
  // }
  // ,[]);
  const dataMenuHaut = data.allMenuHaut.edges.map((item) => item.node);
  const handleClick = () => {
    scroller.scrollTo('formProd', {
      duration: 2000,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -380, // Same offset as in your LinkRose component
    });
  };

    return (
      showBtn && <>
            {/* <FloatButton
      shape="circle"
      badge={{
        dot: true,
      }}
      style={{
     
        right: 24 + 70 + 70,
      }}
    />
    <FloatButton.Group
      shape="circle"
      style={{
        right: 24 + 70,
      }}
    >
      <FloatButton
        href="https://ant.design/index-cn"
        tooltip={<div>custom badge color</div>}
        badge={{
          count: 5,
          color: 'blue',
        }}
      />
      <FloatButton
        badge={{
          count: 5,
        }}
      />
    </FloatButton.Group> */}
     <FloatButtonGroupStyled shape="circle" className={classShow}>
     {showBtnMenu && <FloatButton id="menu"
        // badge={{
        //   count: 123,
        //   overflowCount: 999,
        // }}
        icon = {<AiOutlineMenu  />}
        onClick={toggleDrawer}
      />}
      <FloatButton
        // badge={{
        //   count: 12,
        // }}
        description="Demander conseil"
        icon={<BiQuestionMark  />}
        onClick={() => {
          setFormQuestion(true);
        }}
      />
      {showBtnDoc && <FloatButton
        // badge={{
        //   count: 123,
        //   overflowCount: 999,
        // }}
        description="Recevoir la documentation"
        icon = {<FaRegFileAlt />}
        onClick={handleClick}
      />}
      
      {/* <FloatButton.BackTop visibilityHeight={0} /> */}
    </FloatButtonGroupStyled>
    <DrawerMenu dataMenuHaut={dataMenuHaut} visible={visible} onClose={toggleDrawer} />
    {formQuestion && ReactDOM.createPortal(
        <PopUpContainer>
          <PopUp className="form" gap={"0"}>
              <PopUpTitle>Demander conseil</PopUpTitle>
              <ButFermer onClick={() => setFormQuestion(false)}><IoCloseCircleOutline/></ButFermer>
                <Wrap>
                <FormDemandeDeContactHP className="" onClick={() => setFormQuestion(false)} idContenus={idContenu}/>
                </Wrap>
            </PopUp>
        </PopUpContainer>,
        document.getElementById('portal')
      )} 
  </> 
       
    );
});

export default ButtonsVolant;