import React, { useState, useRef, useEffect } from "react";
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { FormGroup, Label, Input, Col, Row } from "reactstrap";
import { PopUpContainer, PopUp, PopUpTitle, Wrap } from "../DesignSystem/PopUp";
import BoutonJaune from "../DesignSystem/BoutonJaune";
import FormOubliHP from "./FormOubli";
import { navigate } from "@reach/router";
import SpinnerHP from "../Spinners/Spinner1";
import PopErrorHP from "../Popups/PopError";
import axios from "axios";

import { setIsLogged } from '../../reducers/firstSliceReducer';
import { Link } from "gatsby";


const FormConnect = styled(FormGroup)`
width: 90%;
max-width: 100%;
padding: 5px 0;
&>div.flex {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 20px;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }

    &> input {
        width: 240px;
        @media only screen and (max-width: 768px) {
        width: 100%;
        }
    }
    
}
@media only screen and (max-width: 768px) {
    width: 100%;
}   
`
const LabelStyled = styled(Label)`
color:#fff;
margin:0 !important;
font-family: Unbounded;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: 130%;
`

const InputStyled = styled(Input)`
border: none;
border-radius: 0;
border-bottom: 1.5px solid ${props => props.theme.bleuHP};
background-color: #fff;
font-size: 17px;
color: rgba(28, 39, 83, 0.55);
font-family: Epilogue, sans-serif;

&:focus {
    border-color:#a11c7166;
    box-shadow: 0 0 0 0.25rem #a11c7125;
}
`
const DivCheckMemo = styled.div`
display: flex;
align-items: center;
gap: 10px;
padding: 10px 0;
`

const LinkStyled = styled(Link)`
min-width: 200px;
color: #fff !important;
cursor: pointer;
font-size: 15px;
&:hover {
color: ${(props) => props.theme.jaune} !important;  
}
`

const LinkStyled2 = styled.a`
min-width: 200px;
color: #fff !important;
cursor: pointer;
font-size: 15px;
&:hover {
color: ${(props) => props.theme.jaune} !important;  
}
`


const RowStyled = styled(Row)`
margin: 50px 0;
justify-content: center;
width: 100%;
align-items: center;
gap: 50px;
position: relative;
@media only screen and (max-width: 768px) {
    gap: 20px;
    margin: 25px 0;
    flex-direction: column-reverse;
}
`

const ColFlex = styled(Col)`
display: flex;
align-items: center;
@media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
}
`
const Btn = styled.a`
text-decoration: none !important;
border: none !important;
background: #fff;
color: ${props => props.theme.bleuHP};
border-radius: 5px; padding: 20px; 
display: flex; 
flex-direction: row; 
gap: 10px; align-items: flex-end; 
justify-content: center;
align-items: center;
width: ${props => props.width || "240px"};
margin: ${props => props.margin || "0"};
position: relative;
cursor: pointer;color: rgb(10, 65, 120); 
text-align: left; font: 600 17px Quicksand, sans-serif; 
text-transform: uppercase;
transition: all .2s ease-out;
&.j:hover {
    background: ${props => props.theme.jaune};
}
&.r:hover {
    background: ${props => props.theme.rose};
    color: #fff;
}
`

export function FormConnectionSimpleHP({toggleFormConnexion}) {
    const [user, setUser] = useState(null);
    const popupRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [isFormOpen1, setFormOpen1] = useState(false);
    const [isFormOpen2, setFormOpen2] = useState(false);
    const [Login, setLogin] = useState("");
    const [MDP, setMDP] = useState("");
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [txt, setTxt] = useState("");

    // const dispatch = useDispatch();
    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem("user")));
    }, []);

    const userLevel = user?.Client === true ? "Client" : user?.Prospect === true ? "Membre" : "";

    const handleSubmitConnection = (e) => {
        setIsLoading(true);
        axios.post("/users/connexion", { "Login": Login, "MDP": MDP }, { headers: { "Content-Type": "application/json" } })
            .then(res => {
                //  dispatch(setIsLogged(true));
                //  dispatch({type: "SET_USER", payload: res.data
                // });
                localStorage.setItem("user", JSON.stringify(res.data));
                navigate("/membre-connecte");
                setIsLoading(false);
                //  props.onLogin(true); 
                // setIsErrorVisible(true);
                // setTxt("Vous êtes connecté");
                toggleFormConnexion();
            })
            .catch(err => {
                console.log("err", err);
                setIsLoading(false);
                //  props.onLogin(false); 
                setTxt(err?.response?.data?.Erreur ? err.response?.data?.Erreur : "Nos services sont actuellement indisponibles, veuillez réessayer plus tard");
                setIsErrorVisible(true);
                //  dispatch({type: "DELETE_USER"})
                localStorage.removeItem("user");
            })
        console.log("Connexion");
    }

    const handleSubmitDeconnection = (e) => {
        setIsLoading(true);
        axios.post("/users/membredeconnexiontotal", { headers: { "Content-Type": "application/json" } })
            .then(res => {
                localStorage.removeItem("user");
                // dispatch({type: "DELETE_USER"})
                navigate("/membre");
                setIsLoading(false);
                // props?.onLogin(false);
                setIsErrorVisible(true);
                setTxt("Vous êtes déconnecté(e)");
                console.log("res", res);
            })
            .catch(err => {
                console.log("err", err);
                setIsLoading(false);
                setIsErrorVisible(true);
                setTxt(err?.response?.data?.Erreur ? err.response.data?.Erreur : "Vous êtes déconnecté(e)");
                localStorage.removeItem("user");
                navigate("/membre");
            })

    }



    return (
        <>
            <RowStyled id="form">
                {isLoading && <SpinnerHP />}
                {isErrorVisible && <PopErrorHP message={txt} titre={"Espace membre"} onClick={() => setIsErrorVisible(false)} />}
                {!user ?
                    <>
                        <Col lg={3}>
                            <LinkStyled2 href="/membre#section-signup"
                            >PREMIÈRE CONNEXION ?</LinkStyled2>
                        </Col>


                        <Col lg={7}>
                            <FormConnect onSubmit={handleSubmitConnection}   >
                                <div className="flex">
                                    <InputStyled id="Login"
                                        name="Login"
                                        placeholder="Identifiant"
                                        type="text"
                                        onChange={(e) => setLogin(e.target.value)}
                                        value={Login} />
                                    <LinkStyled to="" onClick={() => setFormOpen1(true)}>Identifiant oublié ?</LinkStyled>
                                </div>
                                <DivCheckMemo>
                                    <Input type="checkbox" />
                                    <LabelStyled style={{ marginLeft: 10 }}>Mémoriser l'identifiant</LabelStyled>
                                </DivCheckMemo>

                            </FormConnect>
                            <FormConnect>
                                <div className="flex">
                                    <InputStyled
                                        id="MDP"
                                        value={MDP}
                                        name="MDP"
                                        placeholder="Mot de passe"
                                        type="password"
                                        onChange={(e) => setMDP(e.target.value)}
                                    />
                                    <LinkStyled to="" onClick={() => setFormOpen2(true)}>Mot de passe oublié ?</LinkStyled>
                                </div>
                            </FormConnect>
                            <BoutonJaune type="submit" className="withBorder" onClick={handleSubmitConnection} libellé="Connexion"></BoutonJaune>
                        </Col>
                    </>
                    :
                    <ColFlex lg={12}>
                        <Btn className="j" margin={"auto"} href={"/membre-connecte"}>MON ESPACE {userLevel}</Btn>
                        <Btn type="submit" className="r" margin={"auto"} onClick={handleSubmitDeconnection}>Déconnexion</Btn>
                    </ColFlex>}
                {isFormOpen1 && ReactDOM.createPortal(
                    <PopUpContainer>
                        <PopUp>
                            <PopUpTitle>Identifiant oublié</PopUpTitle>
                            <Wrap onClick={(e) => e.stopPropagation()}>
                                <FormOubliHP className="identifiant" onClick={() => setFormOpen1(false)} />
                            </Wrap>
                        </PopUp>
                    </PopUpContainer>,
                    document.getElementById('form')
                )}
                {isFormOpen2 && ReactDOM.createPortal(
                    <PopUpContainer>
                        <PopUp>
                            <PopUpTitle>Rappel de mon mot de passe</PopUpTitle>
                            <Wrap>
                                <FormOubliHP className="mdp" onClick={() => setFormOpen2(false)} />
                            </Wrap>
                        </PopUp>
                    </PopUpContainer>,
                    document.getElementById('form')
                )}
            </RowStyled>


        </>
    )
}

export default FormConnectionSimpleHP;
