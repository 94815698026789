import styled from 'styled-components';
import  {Form,FormGroup ,Label, Input, Button} from "reactstrap";
import {Input as InputAntd} from "antd"
import Fade from 'reactstrap/lib/Fade';
import {fadeIn} from "./Animations";
import { GatsbyImage } from 'gatsby-plugin-image';

export const ButtonStyled = styled(Button)`
background-color: transparent !important;
border: none !important;
color: ${props => props.theme.bleuClair} !important;
font: 600 18px Quicksand, sans-serif;
display: flex;
align-items: center;
justify-content: center;
&:hover {
  color: ${props => props.theme.rose} !important;
}
&.active {
  color: ${props => props.theme.rose} !important;
}
&.open {
  color: #6e6b5f!important;
}
&> svg {
    height: 23px;
    width: 22px;
    margin-left: 8px;
}
`
export const StyledFade = styled(Fade)`
    width: 100%;
    height: auto;
    &.fade:not(.show){
    max-height: 0px; 
    margin: -24px 0 0 0;
  }
&.fade {
  transition: all .15s linear;
}
`
export const StyledInput = styled(Input)`
width: 1.4em;
height: 1.4em;
border-radius: 0.45em !important;
margin: 0 10px 0 0;
&[type="checkbox"] {
  box-shadow: 0 0 0 0.25rem rgba(10, 65, 120, 0.25);
  border-color: rgb(10, 65, 120);
}
&:checked {
  border-color: rgb(10, 65, 120);
  background-color: rgb(10, 65, 120) ;
}
&:focus {
      box-shadow: 0 0 0 0.25rem rgba(10, 65, 120, 0.25);
      border-color: rgb(10, 65, 120);
    }
`

export const StyledLabel = styled(Label)`
text-align: left;
font: 400 15px Epilogue, sans-serif;
`;

export const DivFiltre = styled.div`
padding:10px 0;
gap: 15px;
&.flex {
display: flex;
flex-wrap: wrap;
}
&.column {
flex-direction: column;
/* gap: 5px; */
}
`
export const Libelle = styled.span`
display: inline-block;
color: rgb(10, 65, 120);
text-align: left;
font-size: 2rem;
font: 600 18px Quicksand, sans-serif;
/* margin-bottom: 10px; */
&.flex {
  flex-basis: 100%;
}
`

export const FormGroupStyled = styled(FormGroup)`
display: flex;
align-items: center;
gap: 10px;
&.flex {
  /* flex-basis: 33%; */
      gap: 10px;
}
`

export const SelectFilters = styled(Input)`
color: ${(props) => props.theme.bleuClair};
text-align: left;
/* font-family: Epilogue, sans-serif; */
font: 600 17px Quicksand, sans-serif;
padding: 10px 8px;
position: relative;
/* line-height: 2.3; */
margin-bottom: 0 !important;
border-color: rgba(28, 39, 83, 0.5);
&.fs17 {
  font-size: 17px !important;
}
&:focus {
  box-shadow: 0 0 0 0.25rem rgba(161, 28, 113, 0.25);
  border-color:  ${(props) => props.theme.rose};
}
@media screen and (max-width: 576px) {
  font-size: 16px !important;
}
`;
export const SelectFilters2 = styled.input`
color: ${(props) => props.theme.bleuClair};
text-align: left;
/* font-family: Epilogue, sans-serif; */
font: 600 17px Quicksand, sans-serif;
padding: 10px 8px;
position: relative;
/* line-height: 2.3; */
margin-bottom: 0 !important;
border-color: rgba(28, 39, 83, 0.5);
&.fs17 {
  font-size: 17px !important;
}
&:focus {
  box-shadow: 0 0 0 0.25rem rgba(161, 28, 113, 0.25);
  border-color:  ${(props) => props.theme.rose};
}
@media screen and (max-width: 576px) {
  font-size: 16px !important;
}
`;
export const SelectFiltersAntd = styled(InputAntd.Password)`
color: ${(props) => props.theme.bleuClair};
text-align: left;
width: 400px;
/* font-family: Epilogue, sans-serif; */
font: 600 17px Quicksand, sans-serif;
padding: 10px 8px;
position: relative;
/* line-height: 2.3; */
margin-bottom: 0 !important;
border-color: rgba(28, 39, 83, 0.5);
&.fs17 {
  font-size: 17px !important;
}
&:focus {
  box-shadow: 0 0 0 0.25rem rgba(161, 28, 113, 0.25);
  border-color:  ${(props) => props.theme.rose};
}
@media screen and (max-width: 576px) {
  font-size: 16px !important;
  width: 100%;
}
`;

export const TextareaStyled = styled.textarea`
color: ${(props) => props.theme.bleuClair};
border-radius: 0.375rem;
text-align: left;
font-family: Epilogue, sans-serif;
padding: 10px 8px;
font-size: 18px !important;
position: relative;
min-height: 100px;
/* line-height: 2.3; */
margin-bottom: 0 !important;
border-color: rgba(28, 39, 83, 0.5);
&:focus {
  box-shadow: 0 0 0 0.25rem rgba(161, 28, 113, 0.25);
  border-color:  ${(props) => props.theme.rose};
}
@media screen and (max-width: 576px) {
  font-size: 16px !important;
}
`;
export const SpanDots = styled.span`
border-bottom: dotted 2px ${(props) => props.theme.bleuTextes2};
margin: 0px 7px 4px;
flex: 1 1 auto;
min-width: 20px;

@media (max-width: 522px) {
display: none;
}
`;

export const LabelFilter = styled(Label)`
color: rgb(10, 65, 120);
text-align: left;
font: 600 18px Quicksand, sans-serif;
position: relative;
&.m0 {
  margin: 0 !important;
}
`;
export const InputFilter = styled(Input)`
width: 2em;
height: 2em;
border-radius: 0.45em !important;
margin-top: 0;
&:checked {
  border-color: rgb(10, 65, 120);
  background-color: rgb(10, 65, 120) ;
}
&:focus {
      box-shadow: 0 0 0 0.25rem rgba(10, 65, 120, 0.25);
      border-color: rgb(10, 65, 120);
    }
`

export const FormFilters = styled(Form)`
width: 100%;
display: flex;
flex-direction: column;
gap: 24px;
`;

export const FormFiltersMode = styled(Form)`
display: flex;
align-items: center;
gap: 15px;
width: 100%;
&.column {
  flex-direction: column;
}
@media (max-width: 768px) {
  width: 100%;
  flex-direction: column;
  align-content: flex-start;
  flex-wrap: wrap;
  &>label {
    width: 100%;
    margin-bottom: 0px;
  }
}
`;

export const LabelFiltersMode = styled(Label)`
width: 110px;
color: rgb(10, 65, 120);
text-align: left;
font: 400 18px Epilogue, sans-serif;
position: relative;
margin-top: 8px;
`;

export const Number = styled.span`
color:  ${(props) => props.theme.rose};text-align: center; font: 400 24px / 130% Epilogue, sans-serif; position: relative;
margin-bottom: 20px;
  @media (max-width: 992px) {
    margin-bottom: 20px;
  }
`;

export const StyledPath = styled.path``;
export const StyledBr = styled.br``;
export const StyledSvg = styled.svg``;

export const TxtCol = styled.div`
margin-bottom: 0;
display: flex;
align-items: flex-end;
&.prix >span >svg {display: none;}
&.prix.stable>span>svg.stable {display: inline;}
&.prix.hausse>span>svg.hausse {display: inline;}
&.prix.baisse>span>svg.baisse {display: inline;}
&.line {
  &>${SpanDots} {
    display: none;
  }
}
@media (max-width: 768px) {
  width: 100%;
  /* align-items: flex-start; */
}
@media (max-width: 600px) {
  flex-wrap: wrap;
  /* flex-direction: column; */
}
`;
export const SpanBleu = styled.span`
font-size: 16px;
flex: 0 1 auto;
/* display: flex; */
align-items: center;
text-align: right;
/* max-width: 590px; */
@media (max-width: 522px) {
  text-align: left;
  width: 100%;
}
&.fs24 {
  font-size: 24px;
  &> a {
    font-size: inherit;
  }
  @media (max-width: 767px) {
    font-size: 18px;
}
}
&>svg {
  display: none;
  margin: -4px 7px 0px 5px;   
}

&>a[target="_blank"] {
  border-bottom: none !important;
  text-decoration: none;
  &:hover {
    border-bottom: none;
    text-decoration: underline dashed;
  }
}
&>a {
  font-family: Epilogue;
  font-size: 16px;
  border-bottom: none !important;
  text-decoration: none;
  &:hover {
    border-bottom: none;
    text-decoration: underline;
  }
}

`


export const SpanBold = styled.span`
flex: 0 1 auto;
font-weight: 600;
font-size: 16px;
&.fs14 {
  font-size: 14px;
}
color: ${(props) => props.theme.bleuHP};
`;

export const DivWrapper = styled.div`
position: relative;
padding: 100px 170px;
font-family: Unbounded;
display: flex;
flex-direction: column;
align-items: center;

&:nth-child(odd) {
  background-color: #195aa00d;
} 
&.noBckg {
  background: transparent;
}

&.p25 {
  padding-top: 0;
  padding-bottom: 25px;
}

&.tab {
  padding: 10px 70px;
}
 
@media (max-width: 1120px) {
    padding: 50px;
}
@media (max-width: 768px) {
    padding: 20px; 
    &.tab {
      padding: 10px;
    }
}
`
export const Ribs = styled.div`
display: flex;
flex-direction: column-reverse;
width: 100%;
&.colNotRev {
  flex-direction: column;
}

&.gap50 {
  gap: 50px;
}
&.pb50 {
  padding-bottom: 50px;
}
&.pt50 {
  padding-top: 50px;
}
`;

export const Rib = styled.div`
background-color: #fff;
position: relative;
width: 100%;
border: 1px solid ${props => props.theme.bleuHP};
padding: 50px;
margin: 25px 0;
box-shadow: 0 4px 25px 0 rgba(0,0,0,.2);
transition: opacity 15s ease-in-out, height 0.5s ease-in-out;
&.flex {
  display: flex;
  gap: 25px;
  justify-content: space-between;
  flex-wrap: wrap;
}
  &.exit {
    opacity: 0;
    height: 0;
  }
@media (max-width: 768px) {
    padding: 20px;
}
`;
export const Nom = styled.div`
width: 100%;
margin-bottom: 50px;
font-size: 20px;
& span {
    color: ${props => props.theme.bleuHP};
}
& >div {
    color: ${props => props.theme.bleuHP};
}
`;

export const FlexNom = styled.div`
display: flex;
gap: 10px;
`

export const Title = styled.div`
display: flex;
justify-content: space-between;
gap: 50px;
align-items: center;
color: ${props => props.theme.bleuHP};
width: 100%;
margin-bottom: 50px;
font-size: 25px;
&.suivi {
  padding: 50px 120px 0px 120px;
  @media (max-width: 768px) {
  padding: 20px 20px 0px 20px;
}
}
@media (max-width: 768px) {
  flex-wrap: wrap;
}
`;
export const Line = styled.div`
display: inline-block;
width: 10%;
height: 2px;
background-color: rgb(220, 253, 0);
margin: 0px 15px 5px 0px;
`;

export const Buts = styled.div`
display: flex;
justify-content: center;
flex-wrap: wrap;
gap: 20px;
margin-top: 50px;
&.membre {
  &.membre {
  z-index: 1;
  position: absolute; 
  top: 20px;
  right: 20px; 
  margin-top: 0;
  @media only screen and (max-width: 768px) {
    top: 0px;
    right: 10px;
    gap: 0;
    &>button {
      margin: 5px auto;
      max-width: 100%;
    }
  }
}
}
&.heroProd {
  margin: 0;
}
@media (max-width: 600px) {
flex-direction: column;
align-items: center;
gap: 10px;
}
@media (max-width: 768px) {
  &.heroProd {
  margin: 0 auto;
}
}
`;

export const Docs = styled.div`
display: flex;
flex-direction: column;
padding: 30px 0;
gap: 30px;
`

export const TxtItalique = styled.p`
font-style: italic;
margin: 25px 0;
`

export const FadeStyled = styled(Fade)`

&.fade:not(.show){
  height: 0px; 
  padding: 0;
}
&.fade {
  height: auto;
  transition: all .15s linear;
}
`
export const DateBlog = styled.div`
color: ${(props) => props.theme.rose}; 
text-align: left; 
width: 100%;
font: 400 14px Unbounded, sans-serif; 
position: relative;
`;

export const TitreBlog = styled.div`
/* height: 100px; */
/* display: flex;
align-items: center; */
text-align: left; 
font: 400 14px / 25px Unbounded, sans-serif; 
position: relative; 
width: 100%;
color: ${(props) => props.theme.bleuHP};
&>span {
  font: inherit;
}
`;

export const Points = styled.span`
display: block;
position: absolute;
bottom: 15px;
right: 50%;
color: rgba(28, 39, 83, 0.7);
text-align: left;
font: 400 14px / 25px Unbounded, sans-serif;
@media screen and (max-width: 768px) {
  bottom: 2px;
}
`

export const TxtBlogSlider = styled.div`

color: ${(props) => props.theme.bleuTextes};
transition: height 0.3s ease-in-out;
font: 400 14px / 25px Unbounded, sans-serif; width: 100%;
text-align: justify;
`;

export const TxtBlog = styled.div`
text-align: justify;
overflow: hidden;
position: absolute;
background-color: #fff;
bottom: 0;
height: 220px;
width: 100%;
left: 0;
margin: 0px;
padding: 13px 20px 35px;
color: ${(props) => props.theme.bleuTextes};
transition: height 0.3s ease-in-out;
font: 600 15px / 25px 'Montserrat', sans-serif; 
width: 100%;
z-index: 1;
&.commentaires {
  height: 210px;
}
&:hover { 
  height: 100%;
}
@media screen and (max-width: 992px) {

  &.expanded {
    height: 100%;
  }
  &.commentaires.expanded {
    height: 100%;
  }
}
`;

export const ImgBlog = styled.img`
align-self: stretch; 
flex-shrink: 0; 
height: 100%;
width:100%; 
max-width: 100%;
max-height: 100%;
position: relative;
transition: all .3s ease;
display: block;
margin: auto;
`;

export const TxtWrapperBlog = styled.div`
background: rgb(255, 255, 255); 
margin: 25px 15px 15px 15px; 
display: flex; 
flex-direction: column; 
gap: 10px; 
align-items: flex-start; 
justify-content: flex-start; 
align-self: stretch;
position: relative;

@media only screen and (max-width: 992px) {
  gap:15px;
}
`;

export const Space = styled.div`
position: absolute;
width: 100%;
height: 50px;
background-color: white;
bottom: 0;
left: 0;
display: flex;
align-items: center;
justify-content: space-between;
gap: 5px;
color: ${props => props.theme.rose};
&> div {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 20px;
}
&> div.link > a {
  cursor: pointer;
  border: none !important;
  text-decoration: none;
  color: ${props => props.theme.rose};
  display: flex;
  align-items: center;
  gap: 5px;
  &:hover {
    color: ${props => props.theme.bleuHP};
  }
}
`

export const TitreRevue = styled.p`
text-align: left; font: 400 20px / 25px Unbounded, sans-serif; position: relative;
`;

export const Source = styled.span`
font-weight: 700;
`
export const DivMedia = styled.div`
position: absolute;
transform: translate(-50%, -50%);
top:50%;
left: 50%;
width: 850px;
max-height: 60vh;
z-index: 300;
padding: 37px;
background: rgba(0,0,0,.6);
z-index: 180;
background: rgba(0,0,0,.45);
z-index: 2;
&>div {
    height: 100% !important;
    width: 100% !important;
  }
&.video {
}
&.audio {
  height: 180px;
}
`

export const Close = styled.span`
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    font-size: 30px;
    padding: 5px 10px;
    z-index: 1;
    cursor: pointer;
`

export const StyledImgRevue = styled.img`
height: 100%;
width: auto; 
transition: all .3s ease;
display: block;
margin: auto;
z-index: 1;
position: relative;
`;

export const TxtWrapper = styled.div`
background: rgb(255, 255, 255); 
padding: 15px 20px; 
display: flex; 
flex-direction: column; 
gap: 10px; 
align-items: flex-start; 
justify-content: flex-start;
width: 100%;
&>p {
  font-size: 17px;
}
  @media screen and (max-width: 768px) {
  gap:20px
  }
`;

export const TxtRevue = styled.div`
&>*{
color: rgba(28, 39, 83, 0.7) !important; 
text-align: left;
font-size: 15px !important; 
}
width: 100%;
  @media screen and (max-width: 768px) {
  width: 100%;
  }
`;

export const DateRevue = styled.div`
color: ${(props) => props.theme.rose}; text-align: left; font: 400 17px Unbounded, sans-serif; position: relative;
`;


export const ImgWrapperRevue = styled.div`
cursor: pointer;
transition: all .3s ease;
height: 320px;
background-size: cover;
background-position: center;
background-repeat: no-repeat;
position: relative;
width: 100%;
overflow: hidden;
`;

export const ImgWrapperBlog = styled.div`
align-self: stretch; flex-shrink: 0; position: relative;transition: all .3s ease;cursor: pointer;transition: all .3s ease;height: 211px;overflow: hidden;
`;

export const TxtBlanc = styled.div`
color: rgb(255, 255, 255); text-align: left; font: 400 16px / 200% Unbounded, sans-serif; 
`;

export const SpanJaune = styled.span`
color: rgb(220, 253, 0); font: 600 35px Unbounded, sans-serif;
`;

export const TitreBlanc = styled.div`
color: rgb(255, 255, 255); font: 600 35px Unbounded, sans-serif;
`;

export const StyledImgPF = styled.img`
width: 100%;
max-width: 400px;
max-height: 568px;
`;


export const StyledImgPFS = styled(GatsbyImage)`
width: 100%;
max-width: 400px;
max-height: 568px;
`;

export const ImgLinkPF = styled.a`
cursor: pointer;
width: 100%;
max-width: 400px;
max-height: 568px;
transition: all 0.3s ease-in-out;
border-bottom: none !important;
&:hover{
  opacity: 0.9;
  transform: scale(1.05);
}
`;

export const TxtGraph = styled.p`
width: 100%;
text-align: center;
font-size: 16px;
color: rgb(183, 155, 121);
padding: 15px;
@media screen and (max-width: 768px) {
  font-size: 16px;
}
`
export const Ter = styled.span`
    color: ${(props) => props.theme.bleuHP};
    font-weight: bold;
    background: rgb(220 253 0 / 12%);
    padding: 10px;
    border: 2px solid ${(props) => props.theme.jaune};
    border-radius: 5px;
`

export const Tag = styled.a`
background-color: #195aa029;
text-decoration: none;
border:1px solid #fff !important;
font-size: 20px;
border-radius: 5px;
padding: 10px;
margin: 5px;
text-align: center;
color: #fff !important;
transition: all .3s ease;
&:hover {
  text-decoration: none;
  background-color: #fff;
  border-color: ${(props) => props.theme.bleuHP} !important;
  color: ${(props) => props.theme.bleuHP} !important;
}
`
export const FormConnect = styled(FormGroup)`
background: rgba(28, 39, 83, 0.12);
width: 100%;
justify-content: center;
align-items: center;
padding: 20px 100px;
display: flex;
gap:50px;
margin: 35px auto; 
@media only screen and (max-width: 576px) {
flex-direction: column;
padding: 5px 30px;
gap: 20px;
margin-top: 15px;
}  
height: ${props => props.showForm ? '200px' : '150px'};

&.menu {
  padding: 10px;
  gap: 35px;
  margin: 15px auto;
  height: auto;
  @media only screen and (max-width: 576px) {
    gap: 20px;
    }  
}
&.e404 {
    background: transparent;
    width: 80%;
    margin: auto;
    @media only screen and (max-width: 576px) {
        width: 100%;
    }   
}
&.mt-70 {
    margin-top: 70px;
} 
&.nav {
  &>input {
    background: #fff; 
  }
}

&.whiteBckg {
    background: #fff !important;
}
&.noBckg {
    background: transparent !important;
}
&.searchPage {
  padding: 20px 0px;
  margin: 10px 0;
  justify-content: flex-start;
}
&.modif {
  flex-direction: column;
  background: transparent !important;
  padding: 20px;
  gap: 5px;
  height: auto !important;
  margin: 0px auto !important;
}
&.glossaire {
    background: transparent !important;
    padding: 0;
    margin: 0 !important;
    justify-content: flex-end;
    &>input {
        width: 90%;
        @media only screen and (max-width: 1030px) {
          width: 100%;
        } 
    }
}

&.blog {
  max-width: 800px;
}
`

export const InputStyled = styled(Input)`
border: none;
width: 60%;
border-radius: 0;
border-bottom: 2px solid ${props => props.theme.bleuHP};
background-color: transparent;
font-size: 18px;
color:${props => props.theme.bleuHP};
font-family: Epilogue, sans-serif;
&.blue::placeholder {
  color: ${props => props.theme.bleuHP};
}
@media only screen and (max-width: 768px) {
width: 100%;
}  
@media screen and (max-width: 576px) {
  font-size: 16px !important;
}

&:focus {
    background-color: transparent;
    border-color:${(props) => props.theme.rose};
    box-shadow: 0 0 0 0.25rem #a11c7125;
    color:${props => props.theme.bleuHP};
}
`

export const Gras = styled.p`
&.inline {
  display: inline-block;
}
font-weight: 700;
`

export const Italic = styled.p`
&.inline {
  display: inline-block;
}
&.center {
  text-align: center;
}
font-style: italic;
`
export const TxtCenter = styled.p`
text-align: center;
`

export const DivTxtes = styled.div`
padding: 20px 0;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
font-size: 18px;
`

export const Actif = styled.span`
color: ${(props) => props.theme.rose};
`
export const TxtWrapperParagraphes = styled.div`
width: 95%;
`
export const TxtParagraphes = styled.div`
position: relative;
color: ${props => props.theme.bleuHP};
font-family: 'Montserrat', sans-serif;
text-align: left;
font-size: 19px;
&> p >svg.faJaune {
fill: ${props => props.theme.jaune};
}
&> p >svg.faBeige {
fill: ${props => props.theme.beigeFonce};
}
&> p >svg.faBleu {
fill: ${props => props.theme.bleuClair};
}&> p >svg.faRose {
fill: ${props => props.theme.rose};
}
& > a, & > p > a, & > p > span > a {
text-decoration: none !important;
}
&> p >svg.faNoir {
fill: #000;
}
&> p >svg.faNoir {
fill: #000;
}

@media (max-width: 767px) {
font-size: 16px;
}
`
export const SpanTitreTxtSimple = styled.span`
display: block;
position: relative; 
font-size: 25px;
margin-bottom: 25px;
color: #736e5f;
text-transform: uppercase;
@media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export const TxtSimple = styled.div`
position: relative;
width: 95%;
padding: 20px 100px;
margin: 50px auto;
font-family: Unbounded, sans-serif;
font-size: 20px;
text-align: left;
color: ${props => props.theme.bleuHP};
&.maxWidth {
  width: 100%;
  padding: 20px 0;
}

&.noPadding {
    padding: 0;
    width: 100%;
  }
&.fs16 {
  font-size: 16px;
  &>ul>li {
    font-size: 16px;
  }
  &>div>ul>li {
    font-size: 16px;
  }
  &>div> p {
    font-size: 16px;
  } 
  &> p {
    font-size: 16px;
  }
  &>${SpanTitreTxtSimple} {
    font-size: 23px;
  }
}
&.bordered {
  &> div {
  width: 100%;
  padding: 40px;
  border: 1px solid #aba796;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
  background: rgb(255, 255, 255);
  border-radius: 5px;
  }

 
  @media screen and (max-width: 768px) {
    padding: 15px;
    width: 90%;
    margin: 50px auto;
    &.maxWidth {
      width: 100%;
    }
    &.noPadding {
    padding: 0;
    width: 100%;
    &> div {
      padding: 20px;
    }
  }
  }
}
&> p >svg.faJaune {
  fill: ${props => props.theme.jaune};
}
&> p >svg.faBeige {
  fill: ${props => props.theme.beigeFonce};
}
&> p >svg.faNoir {
  fill: #000;
}
  @media screen and (max-width: 600px) {
    padding: 30px 20px;
    margin-bottom: 0;
  }

  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
`;



export const NomProduit = styled.div`
vertical-align: middle;
text-decoration: none;
border: none !important;
color: ${props => props.theme.bleuHP};
font: 500 32px / 140% Unbounded, sans-serif;
transition: all 0.1s ease-in-out;
  @media (max-width: 768px) {
    font-size: 28px;
    text-align: center;
  }
`;

export const Chars = styled.span`
color: ${props => props.theme.grisHP};
font-size: 14px;
`
